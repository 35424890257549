import './Footer.css';

const Footer = () => {
   return (
      <div className="footer-copyright">
         <p>&copy;2021-2022 Lisa Goodell 🏳️‍🌈 🏳️‍⚧️ ✊🏿 🐾</p>
      </div>
   )
}

export default Footer;